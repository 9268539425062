<template>
	<div v-if="page">
		<div class="social-media-bar hidden-desktop">
			<TnButton
				iconOnly="share"
				secondary
				size="s"
				@click="shareMobile"
				:dark="dark"
			/>
		</div>
		<div class="social-media-bar hidden-mobile">
			<TnButton
				iconOnly="x"
				secondary
				:size="$store.getters.isMobile ? 's' : 'l'"
				:href="'https://twitter.com/share?url=' + shareUrl"
				target="_blank"
				alt="Share on X"
				:dark="dark"
			/>
			<TnButton
				iconOnly="facebook"
				secondary
				:size="$store.getters.isMobile ? 's' : 'l'"
				:href="'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl"
				target="_blank"
				alt="Share on facebook"
				:dark="dark"
			/>
			<TnButton
				iconOnly="linked-in"
				secondary
				:size="$store.getters.isMobile ? 's' : 'l'"
				:href="'https://www.linkedin.com/sharing/share-offsite/?url=' + shareUrl"
				target="_blank"
				alt="Share on LinkedIn"
				:dark="dark"
			/>
		</div>
	</div>
</template>

<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import { colorNeutrals900Shade, colorNeutralsWhite } from "@/assets/tokens/js/es6-colors";
import { usePageStore } from "~/pinia/page";
import { mapState } from "pinia";
export default defineNuxtComponent({
	name: "SocialMediaBar",
	props: {
		socialMediaShare: { type: Object, default: () => ({}) },
		forceBorder: { type: Boolean, default: false },
	},

	mixins: [GizmoComponentMixin],

	data() {
		return {
			title: this.socialMediaShare.title,
			text: this.socialMediaShare.text,
			url: this.socialMediaShare.url,
		};
	},

	computed: {
		...mapState(usePageStore, ["page"]),
		shareTitle() {
			return this.title || (this.page && this.page.metadata && this.page.metadata.title);
		},
		shareDescription() {
			return this.text || (this.page && this.page.metadata && this.page.metadata.description);
		},
		shareUrl() {
			return (
				this.url ||
				"https://www.telenor.no" +
					((this.page && this.page.publishUrl && this.page.publishUrl.replace("index.page", "")) || "/")
			);
		},
		shareData() {
			const data = {
				title: this.shareTitle.trim(),
				url: this.shareUrl,
			};

			if (this.shareDescription) data.text = this.shareDescription.trim();

			return data;
		},
		colorNeutrals900Shade() {
			return colorNeutrals900Shade;
		},
		colorNeutralsWhite() {
			return colorNeutralsWhite;
		},
	},

	methods: {
		async shareMobile() {
			if (navigator && navigator.share) {
				try {
					await navigator.share(this.shareData);
				} catch (e) {
					/* empty */
				}
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.social-media-bar {
	display: flex;
	flex-wrap: nowrap;
	gap: $spacing-m;
}
</style>
